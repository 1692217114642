import styled from '@emotion/styled';
import { componentColors, primaryPalette } from '../../theme'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';

const drawerWidthSize = "63px"
const barHeightSize = "48px"
const transitionTime = "0.2s"
const barsColor = componentColors.bar
const barsContrastColor = componentColors.barContrast
const barsDividerColor = componentColors.divider
const dividersSize = "1px"
const selectedItemColor = componentColors.selectedItem

export const MainContainer = styled.div`
  height: 100vh;
`;

export const TopBar = styled.div`
  background-color: ${barsColor};
  height: ${barHeightSize};
  width: 100%;
  border-bottom: ${dividersSize} solid ${barsDividerColor};
`;

export const LogoContainer = styled.div`
  display: flex
`;

export const DrawerPageContainer = styled.div`
  height: calc(100vh - ${barHeightSize} - ${dividersSize});
  width: 100%;
`;

interface DrawerContainerProps {
  isOpen: boolean;
}
export const DrawerContainer = styled.div<DrawerContainerProps>`
  background-color: ${barsColor};
  height: calc(100vh - ${barHeightSize} - ${dividersSize});
  display: flex;
  flex-direction: column;
  transition: width ${transitionTime} ease-in-out;
  width: ${({ isOpen }) => (isOpen ? "200px" : drawerWidthSize)};
  position: absolute;
  z-index: 1;
  border-right: ${dividersSize} solid ${barsDividerColor};
  overflow: hidden;
`;

export const PageContainer = styled.div`
  height: calc(100vh - ${barHeightSize} - ${dividersSize});
  width: calc(100vw - ${drawerWidthSize});
  position: absolute;
  right: 0;
  overflow-y: auto;
`;

export const NavItemContainer = styled(Box)({
  paddingLeft: "18px",
  height: "48px",
  display: 'flex',
  alignItems: 'center'
});

export const WebTitle = styled(Typography)`
  font-size: 1.2em;
  padding-left: 8px;
  color: ${barsContrastColor};
  cursor: default;
`;

interface NavItemSelectorContainerProps {
  isSelected: boolean;
}

export const NavItemSelectorContainer = styled(NavItemContainer) <NavItemSelectorContainerProps>`
  &:hover {
    background-color: ${barsDividerColor};
    border-right: 3px solid ${primaryPalette[200]};
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    border-right: 3px solid ${selectedItemColor} !important;
    background-color: ${barsDividerColor};
    `
  }
`;

interface StyledIconProps {
  isSelected?: boolean;
}

export const StyledIcon = styled(Icon) <StyledIconProps>`
  color: ${({ isSelected }) => (isSelected ? selectedItemColor : barsContrastColor)};
`

interface TextItemsProps {
  isOpen: boolean;
  isSelected?: boolean;
}

export const TextItems = styled(Typography) <TextItemsProps>`
  padding-left: 8px;
  white-space: nowrap;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity ${transitionTime} ease-in-out, visibility ${transitionTime} ease-in-out;
  color: ${({ isSelected }) => (isSelected ? selectedItemColor : barsContrastColor)};
  cursor: default;
`;



//// MOBILE /////

export const TopBarElementsContainerMb = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center
`;

export const DrawerPageContainerMb = styled.div`
  height: calc(100vh - ${barHeightSize} - ${dividersSize});
  width: 100%;
`;

export const DrawerContainerMb = styled.div<DrawerContainerProps>`
  background-color: ${barsColor};
  height: calc(100vh - ${barHeightSize} - ${dividersSize});
  display: flex;
  flex-direction: column;
  transition: width ${transitionTime} ease-in-out;
  width: ${({ isOpen }) => (isOpen ? "100%" : "0px")};
  position: absolute;
  z-index: 1;
  overflow: hidden;
`;

export const PageContainerMb = styled.div`
  height: calc(100vh - ${barHeightSize} - ${dividersSize});
  width: 100%;
  overflow-y: auto;
`;