export interface RankedStats {
  currentEvents: CurrentEvent[];
  event: Event;
}

export interface CurrentEvent {
  id: number;
  name: string;
  mode: string;
}

export interface Event {
  lastUpdate: Date;
  id: number | null;
  name: string | null;
  mode: string | null;
  brawlersStats: BrawlerStats[];
}

export interface BrawlerStats {
  brawlerId: number;
  brawlerName: string;
  wins: number;
  loses: number;
  totalBattles: number;
  winRate: number | null;
  useRate: number | null;
  effectivityRate: number | null;
}

export interface CurrentEvents {
  lastUpdate: Date;
  currentEvents: CurrentEvent[];
}


export const ALL_ID = "all";

export type RankedStatsId = typeof ALL_ID | `${number}`;
