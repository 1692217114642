import { BrawlerStats } from "../../../api/ranked-stats.types";

export enum Order {
    Ascending = 'asc',
    Descending = 'desc',
}

export interface Column {
    title: string;
    key: keyof BrawlerStats;
    tooltip?: string;
}