import { useTranslation } from "react-i18next";
import { useState } from "react";
import { DrawerContainer, DrawerContainerMb, DrawerPageContainer, DrawerPageContainerMb, LogoContainer, MainContainer, NavItemContainer, NavItemSelectorContainer, PageContainer, PageContainerMb, StyledIcon, TextItems, TopBar, TopBarElementsContainerMb, WebTitle } from "./nav-bar.styles";
import { APP_ROUTES } from "../routes.constants";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { screenBreackpoints } from "../../theme";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

interface Props {
  children?: React.ReactNode;
}

interface INavItem {
  id: number;
  label: string;
  icon: string;
  route: string;
}

export const NavBar: React.FC<Props> = ({ children }) => {
  const isMobile = useMediaQuery(`(max-width:${screenBreackpoints.mobile})`);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleNavigation = (route: string) => {
    navigate(route);
    if (isMobile) { setIsOpen(false) }
  };

  const isSelectedItem = (route: string): boolean => {
    return location.pathname === route ||
      (location.pathname === APP_ROUTES.HOME && route === APP_ROUTES.RANKEDS) // this should be removed when a new page are at home
  };

  const toggleDrawer = (open: boolean) => {
    setIsOpen(open)
  };

  const items: INavItem[] = [
    {
      id: 1,
      label: t("NAV_BAR.RANKED"),
      icon: "kid_star",
      route: APP_ROUTES.RANKEDS
    },
    {
      id: 2,
      label: t("NAV_BAR.ABOUT_US"),
      icon: "contact_support",
      route: APP_ROUTES.ABOUT_US
    },
  ]

  const renderLogo = () => (
    <LogoContainer>
      <Box
        component="img"
        sx={{ height: 24 }}
        src={`/logo192.png`}
      />
      <WebTitle>Topbrawl</WebTitle>
    </LogoContainer>
  );

  const renderDrawerItems = (items: INavItem[]) => (
    items.map((item) => (
      <NavItemSelectorContainer key={item.id} onClick={() => handleNavigation(item.route)} isSelected={isSelectedItem(item.route)}>
        <StyledIcon baseClassName="material-symbols-outlined" isSelected={isSelectedItem(item.route)}>{item.icon}</StyledIcon>
        <TextItems isOpen={isOpen} isSelected={isSelectedItem(item.route)}>{item.label}</TextItems>
      </NavItemSelectorContainer>
    ))
  );

  return (
    <MainContainer>
      {isMobile ?
        <div>
          <TopBar>
            <TopBarElementsContainerMb>
              <IconButton onClick={() => setIsOpen(!isOpen)}>
                <Icon baseClassName="material-symbols-outlined">menu</Icon>
              </IconButton>
              <Box onClick={() => handleNavigation(APP_ROUTES.HOME)}>
                {renderLogo()}
              </Box>
              <Box sx={{ width: '48px' }} >&nbsp;</Box>
            </TopBarElementsContainerMb>
          </TopBar>
          <DrawerPageContainerMb>
            <DrawerContainerMb isOpen={isOpen}>
              {renderDrawerItems(items)}
            </DrawerContainerMb>
            <PageContainerMb>
              {children}
            </PageContainerMb>
          </DrawerPageContainerMb>
        </div>
        :
        <div>
          <TopBar>
            <NavItemContainer onClick={() => handleNavigation(APP_ROUTES.HOME)}>
              {renderLogo()}
            </NavItemContainer>
          </TopBar>
          <DrawerPageContainer>
            <DrawerContainer isOpen={isOpen} onMouseEnter={() => toggleDrawer(true)} onMouseLeave={() => toggleDrawer(false)}>
              {renderDrawerItems(items)}
            </DrawerContainer>
            <PageContainer>
              {children}
            </PageContainer>
          </DrawerPageContainer>
        </div>
      }
    </MainContainer>
  );
};
