import { Box, Link, Typography } from "@mui/material";
import { componentColors } from "../../theme";

export const Footer: React.FC = () => {
    return (
        <Box>
            <Typography component="footer">
                This content is not affiliated with, endorsed, sponsored, or specifically approved by Supercell and Supercell is not responsible for it. <br />
                For more information see{' '}
                <Link href="https://supercell.com/en/fan-content-policy/" color={componentColors.linkText} underline="hover" target="_blank">
                    Supercell's Fan Content Policy
                </Link>
            </Typography>
        </Box>
    );
}
