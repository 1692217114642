import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const AboutUs: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h6" component="div" sx={{ mr: 20, ml: 4 }}>
        ABOUT US
      </Typography>
    </Box>
  );
}
