import styled from '@emotion/styled';
import { Box, TableCell, TableSortLabel } from '@mui/material';
import { componentColors, componentStyles } from '../../../theme';

interface TableCellStyledProps {
  isMobile?: boolean;
}

export const TableCellStyled = styled(TableCell) <TableCellStyledProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${({ isMobile }) => (isMobile ? "2px" : "8px")};
  font-size: 1em;
`

export const TableCellName = styled(TableCellStyled) <TableCellStyledProps>`
  max-width: ${({ isMobile }) => (isMobile ? "48px" : "74px")};
`

interface TableCellIconStyledProps {
  isMobile?: boolean;
}

export const TableCellIconStyled = styled(TableCell) <TableCellIconStyledProps>`
  padding: ${({ isMobile }) => (isMobile ? "4px" : "8px")};
`

export const TableSortLabelStyled = styled(TableSortLabel)`
  white-space: nowrap;
`

interface TableContainerProps {
  isMobile?: boolean;
}

export const TableContainer = styled(Box) <TableContainerProps>`
  background-color: ${componentColors.card};
  display: 'inline-block';
  border: "1px solid ${componentColors.cardBorder}";
  border-radius: ${componentStyles.cardRadius};
  padding: ${({ isMobile }) => (isMobile ? "16px 8px 16px" : "16px")};
`