import { Navigate, Route, Routes } from "react-router-dom";
import { APP_ROUTES } from "./routes.constants";
import { Rankeds } from "../pages/rankeds/rankeds";
import { AboutUs } from "../pages/about-us/about-us";

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={APP_ROUTES.HOME} element={<Rankeds />} />
      <Route path={APP_ROUTES.RANKEDS} element={<Rankeds />} />
      <Route path={APP_ROUTES.ABOUT_US} element={<AboutUs />} />
      <Route path="*" element={<Navigate to={APP_ROUTES.HOME} />} />
    </Routes>
  );
};
