import Box from "@mui/material/Box";
import { ALL_ID, CurrentEvent, RankedStatsId } from "../../../api/ranked-stats.types";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AllMapsContainer, MapContainer, ModeContainer, ModeTitle } from "./rankeds-maps.styles";
import { RankedMaps } from "./rankeds-maps.types";
import { transformToRankedMaps } from "./rankeds-maps.functions";

interface Props {
  selectedMapId: RankedStatsId;
  currentEvents: CurrentEvent[];
  onClickMap: (eventId: RankedStatsId) => void;
}

export const RankedsMaps: React.FC<Props> = ({
  selectedMapId,
  currentEvents,
  onClickMap
}: Props) => {
  const [rankedMaps, setRankedMaps] = useState<RankedMaps[]>([]);

  useEffect(() => {
    const eventsTransformed = transformToRankedMaps(currentEvents)
    setRankedMaps(eventsTransformed)
  }, [currentEvents]);

  return (
    <Box>
      <AllMapsContainer>
        <MapContainer onClick={() => onClickMap(ALL_ID)} isSelected={ALL_ID === selectedMapId}>
          <Typography component="h3">
            All Maps
          </Typography>
        </MapContainer>
      </AllMapsContainer>
      {rankedMaps.map((mode) => (
        <Box key={mode.mode}>
          <ModeTitle component="h3">
            {mode.mode.replace(/_/g, ' ')}
          </ModeTitle>
          <ModeContainer>
            {mode.maps.map((event) => (
              <MapContainer key={event.id} onClick={() => onClickMap(event.id)} isSelected={event.id.toString() === selectedMapId}>
                <Typography component="h6">
                  {event.name}
                </Typography>
                <Box
                  component="img"
                  sx={{ height: 260 }}
                  src={`/images/maps/${event.id}.webp`}
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = '/images/maps/unknown.webp';
                  }}
                />
              </MapContainer>
            ))}
          </ModeContainer>
        </Box>
      ))}
    </Box>
  );
}
