import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { componentColors, componentStyles } from '../../../theme';

interface MapContainerProps {
  isSelected: boolean;
}

export const MapContainer = styled(Box) <MapContainerProps>`
  padding: 16px;
  background-color: ${componentColors.card};
  border: 1px solid ${componentColors.cardBorder};
  border-radius: ${componentStyles.cardRadius};
  transition: transform 0.1s ease;

  &:hover {
    border-color: ${componentColors.selectedItem};
    box-shadow: 0px 0px 8px ${componentColors.selectedItem}60;
    transform: scale(1.03);
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    border-color: ${componentColors.selectedItem};
    `
  }
`;

interface ModeTitleProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  component?: string;
}

export const ModeTitle = styled(Typography)<ModeTitleProps>`
  font-size: 1.2em;
  padding-bottom: 8px;
`;

export const ModeContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 16px;
`;

export const AllMapsContainer = styled(Box)`
  padding-bottom: 16px;
`;
