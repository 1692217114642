import Box from "@mui/material/Box";
import { BrawlerStats, Event } from "../../../api/ranked-stats.types";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Avatar, TablePagination, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { TableContainer, TableSortLabelStyled, TableCellStyled, TableCellIconStyled, TableCellName } from "./rankeds-table.styles";
import React from "react";
import { Column, Order } from "./rankeds-table.types";
import { formatPercentaje, getImageSrc, sortBrawlerStats } from "./rankeds-table.functions";
import { screenBreackpoints } from "../../../theme";

interface Props {
  event: Event;
}

export const RankedsTable: React.FC<Props> = ({
  event,
}: Props) => {
  const isMobile = useMediaQuery(`(max-width:${screenBreackpoints.mobile})`);
  const rowsNumberOptions = [10, 20, 40, event.brawlersStats.length];
  const [order, setOrder] = React.useState<Order>(Order.Descending);
  const [orderBy, setOrderBy] = React.useState<keyof BrawlerStats>('winRate');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(isMobile ? rowsNumberOptions[0] : rowsNumberOptions[2]);
  const columns: Column[] = [
    { title: "Brawler", key: "brawlerName" },
    { title: "Wins %", key: "winRate" },
    { title: "Use %", key: "useRate" },
    { title: "Score", key: "effectivityRate", tooltip: "High win rate and low score is a situational brawler" },
  ]

  const sortColumn = (
    columnKey: keyof BrawlerStats,
  ) => {
    const newOrder = orderBy === columnKey
      ? (order === Order.Ascending ? Order.Descending : Order.Ascending)
      : Order.Descending;
    setOrder(newOrder);
    setOrderBy(columnKey);
  };

  const changePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const changeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleStats = React.useMemo(
    () =>
      sortBrawlerStats(event.brawlersStats, orderBy, order).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, event.brawlersStats],
  );

  return (
    <Box>
      <TableContainer isMobile={isMobile}>
        <Typography component="h2" fontSize={"1.2em"}>
          Best Brawlers for {event.id?.toString() === undefined ? "All Maps" : event.mode?.replace(/_/g, ' ').toLowerCase() + " on " + event.name}
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {columns.map((column) => (
                <TableCellStyled key={column.key} isMobile={isMobile}>
                  <TableSortLabelStyled
                    active={orderBy === column.key}
                    direction={order}
                    onClick={() => sortColumn(column.key)}
                  >
                    <Tooltip title={column.tooltip} placement="top" enterDelay={800}>
                      <Box>
                        {column.title}
                      </Box>
                    </Tooltip>
                  </TableSortLabelStyled>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleStats.map((brawlerStats) => (
              <TableRow key={brawlerStats.brawlerId}>
                <TableCellIconStyled isMobile={isMobile}>
                  <Avatar src={getImageSrc(brawlerStats.brawlerName)} >
                    <Avatar src="/images/brawlers-icon/unknown.webp" />
                  </Avatar>
                </TableCellIconStyled>
                <TableCellName isMobile={isMobile}>{brawlerStats.brawlerName}</TableCellName>
                <TableCellStyled isMobile={isMobile}>{formatPercentaje(brawlerStats.winRate)}</TableCellStyled>
                <TableCellStyled isMobile={isMobile}>{formatPercentaje(brawlerStats.useRate)}</TableCellStyled>
                <TableCellStyled isMobile={isMobile}>{formatPercentaje(brawlerStats.effectivityRate)}</TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={rowsNumberOptions}
          component="div"
          count={event.brawlersStats.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
